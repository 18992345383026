import React from "react";
import logo from "../res/logo.png"

export default class Footer extends React.Component {
    _mounted;
    constructor(props){
        super(props);
    }

    componentDidMount(){
        this._mounted = true;
    }

    componentWillUnmount(){
        this._mounted = false;
    }

    render(){
        return <div className="footer" id="contacto">
            <div className="row mx-0">
                <div className="col-sm-6 px-4 mt-5">
                    <img src={logo} className="logo"/>

                    <p className="mt-3">Somos tu aliados de cuidado dental. Brindamos información dinámica sobre el cuidado dental y prevención</p>

                    <div className="flex-left">
                    <a href="#" class="fa fa-instagram"></a>
                   

                   
                    <a href="#" class="fa fa-facebook ml-1"></a>
                    

                 
                    <a href="#" class="fa fa-pinterest"></a>
                    
                    </div>
                </div>

                <div className="col-sm-3 px-4 mt-5">
                    <p><b>Sitios de interés</b></p>

                    <a className="mt-3" href="#home">Home</a>
                    <a className="mt-2" href="#nosotros">Nosotros</a>
                    <a className="mt-2" href="#contacto">Contáctanos</a>
                </div>

                <div className="col-sm-3 px-4 mt-5">
                    <p><b>Dentistas</b></p>

                    <a className="mt-3">Regístrate</a>
               
                </div>
            </div>

            <hr className="mt-5"/>

            <div className="flex-sb mt-3">
                <p className="mb-0">(©) Copyright 2020 <a className="special-link">Tusdentistas</a></p>

                <a>Términos y condiciones</a>
            </div>
        </div>
    }
}