import React, { lazy, Suspense } from 'react';
import logo from './logo.svg';
import './styles/sass/App.scss';
import {BrowserRouter as Router, Switch, NavLink, Route, Redirect} from "react-router-dom";
import { Position, Toast, Toaster, Classes} from "@blueprintjs/core";

import CircleLoader from "../src/components/loaders/CircleLoader";
import Footer from "../src/components/Footer";

const Patients = lazy(() => import("./components/Patients"));
const Dentists = lazy(() => import("./components/Dentists"));
const AdminPanel = lazy(() => import("./components/AdminPanel"));


class App extends React.Component {
  _mounted;
  constructor(props){
    super(props);

    this.state = {
      toasts:[]
    }

    this.toaster = {};
        this.refHandlers = {
            toaster:(ref) => {this.toaster = ref},
        }
  }

  addToast = (message) => {
    this.toaster.show({ message: message});
}


  componentDidMount(){
    this._mounted = true;

    
  }

  componentWillUnmount(){
    this._mounted = false;
  }

  render(){
    return <div className="app">
      <Toaster className={Classes.OVERLAY} position={Position.TOP} ref={this.refHandlers.toaster}>
                    {/* "Toasted!" will appear here after clicking button. */}
                    {this.state.toasts.map(toast => <Toast action={{onClick:() => {}, text:"Resend"}} {...toast} />)}
           </Toaster>
      <Suspense fallback={<CircleLoader/>}>
        <Router>
          <Switch>
            <Route exact={true} path="/" render={() => <Redirect to="/pacientes"/>}/>
            <Route exact={true} path="/pacientes" render={() => <Patients addToast={this.addToast}/>}/>
            <Route exact={true} path="/dentistas" render={() => <Dentists addToast={this.addToast}/>}/>
            <Route exact={true} path="/admin-panel" render={() => <AdminPanel addToast={this.addToast}/>}/>
          </Switch>

          <Footer/>
        </Router>
      </Suspense>
      
    </div>
  }

}

export default App;
